import React from "react"
import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import SetAutoDeposit from "@components/_molecules/auto_deposit_set"
import BankSettings from "@components/bank_settings"

const BankSettingsPage = () => {
  const { bankSettings } = data
  return (
    <Layout type="app">
      <SEO title={bankSettings.title} />
      <BankSettings />
    </Layout>
  )
}

export default BankSettingsPage
