export const oneTimeDeposit = {
  heading: {
    pageTitle: "One-Time Deposit",
    link: "/app/"
  }
}

export const accountClosure = {
  title: "Account Closure",
  heading: {
    pageTitle: "Close Learn & Earn Account",
    link: "/app/bank-settings"
  }
}

export const linkedAccount = {
  title: "Linked Account",
  heading: {
    pageTitle: "Linked Account",
    link: "/app"
  },
  modal: {
    title: "Unlink Your Bank Account",
    copy: "Are you sure you would like to unlink your bank account?"
  }
}

export const accountInfo = {
  title: "Account Info",
  heading: {
    pageTitle: "Account Info",
    link: "/app"
  }
}
export const bankSettings = {
  title: "Bank Info",
  heading: {
    pageTitle: "Bank Info",
    link: "/app"
  }
}

export const courseActivity = {
  title: "Course Activity",
  heading: {
    pageTitle: "Course Activity",
    link: "/app",
    header: "Welcome back 👋",
    text: "Olivia’s account is up $56.23, that’s great!"
  }
}
export const autoDeposit = {
  title: "Auto-Deposit",
  heading: {
    pageTitle: "Auto-Deposit",
    link: "/app"
  },
  zeroDollarModal: {
    title: "Disable Auto Deposit",
    copy:
      "Setting your Auto Deposit to $0.00 will also unlink your bank account. Are you sure you would like to continue?"
  },
  disableModal: {
    title: "Disable Auto Deposit",
    copy:
      "Are you sure you would like to cancel your Auto-Deposit? You can always enable it again later."
  }
}
export const statements = {
  title: "Your Documents & Statements",
  heading: {
    pageTitle: "Your Documents & Statements",
    link: "/app"
  }
}
export const fundingAccount = {
  title: "Account Balance",
  heading: {
    pageTitle: "Account Balance",
    link: "/app",
    header: "Welcome back 👋",
    text: "Olivia’s account is up $56.23, that’s great!"
  }
}
export const parentProfile = {
  title: "Parent Profile",
  heading: {
    pageTitle: "Home",
    header: "Welcome back 👋",
    text: "Olivia’s account is up $56.23, that’s great!",
    link: "/app"
  }
}
