import React, { Component } from "react"
import "./style.scss"

interface IComponentProps {
  className?: string
  loading: boolean
}
interface IComponentState {}
class Loading extends Component<IComponentProps, IComponentState> {
  constructor(props: IComponentProps) {
    super(props)
    this.state = { loading: false, className: null }
  }
  render() {
    return (
      <div className={this.props.className}>
        {this.props.loading ? (
          <p className="loading">Loading</p>
        ) : (
          this.props.children
        )}
      </div>
    )
  }
}

export default Loading
