import React, { Component } from "react"
import "./style.scss"
import axios from "axios"
import * as headers from "@data/headers"
import BackPage from "@components/_molecules/back_parent_dashboard"
import FormButton from "@components/_atoms/button_form_input_submit"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"
import * as data from "@data/parentDash"
import { format } from "path"
import * as errors from "@services/errors"
import { Link } from "gatsby"
import Loading from "@components/_molecules/loading_state"

class BankSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      autoAmount: null,
      deletedAt: null,
      accountNumber: null,
      accountName: null,
      loading: true
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      [`${event.target.name}ErrorStatus`]: false,
      generalError: null
    })
  }

  handleSubmit = event => {
    event.preventDefault()
  }

  componentDidMount = () => {
    let _this = this
    let autoDepositQuery = {
      query: `query {
        user {
          recurringDeposits {
            nodes {
              id
              amount
              scheduledAt
              paymentInterval
            }
          }
          paymentAccounts(linked: true) {
            id
            name
            displayAccountNumber
          }
        }
      }
      `
    }
    const options = {
      method: "POST",
      data: JSON.stringify(autoDepositQuery),
      headers: headers.getHeaders(),
      url: headers.api
    }
    axios(options)
      .then(res => {
        if (res.data.errors) {
          _this.errorHandler(res.data.errors[0].message)
        } else {
          if (res.data.data.user.recurringDeposits.nodes.length === 0) {
            this.setState({ loading: false })
          } else {
            _this.setState({
              autoAmount: res.data.data.user.recurringDeposits.nodes[0].amount,
              deletedAt:
                res.data.data.user.recurringDeposits.nodes[0].deletedAt,
              accountNumber:
                res.data.data.user.paymentAccounts[0].displayAccountNumber,
              accountName: res.data.data.user.paymentAccounts[0].name,
              loading: false
            })
          }
          if (res.data.data.user.paymentAccounts.length === 0) {
            _this.setState({ loading: false })
          } else {
            _this.setState({
              accountNumber:
                res.data.data.user.paymentAccounts[0].displayAccountNumber,
              accountName: res.data.data.user.paymentAccounts[0].name
            })
          }
        }
      })
      .catch(err => console.log(err))
  }

  errorHandler = err => {
    this.setState({
      generalError: errors.messaging(err)
    })
  }

  formatDate = date => {
    var options = {
      month: "short",
      day: "numeric"
    }

    return new Date(date).toLocaleDateString("en-US", options)
  }
  errorHandler = err => {
    this.setState({
      generalError: errors.messaging(err)
    })
  }

  render() {
    const { bankSettings } = data
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0
    })

    return (
      <section className="standard">
        <div className="heading-size">
          <BackPage data={bankSettings.heading} />
        </div>
        <div className="bank-settings">
          <p className="label">BANK SETTINGS</p>
          <Loading className="setting" loading={this.state.loading}>
            <p>Auto Deposit</p>
            <Link to="/app/auto-deposit" className="a-with-state">
              {this.state.autoAmount !== null
                ? currencyFormatter.format(this.state.autoAmount) + "/month"
                : "Set Auto Deposit"}
            </Link>
          </Loading>
          <p className="label">LINKED ACCOUNT</p>
          <Loading className="setting" loading={this.state.loading}>
            <p>{this.state.accountName}</p>
            <Link to="/app/linked-account" className="a-with-state">
              {this.state.accountNumber !== null
                ? "*****" + this.state.accountNumber
                : "N/A"}
            </Link>
          </Loading>
          <div className="setting">
            <Link to="/app/account-closure">Close Learn & Earn Account</Link>
          </div>
        </div>
      </section>
    )
  }
}
export default BankSettings
